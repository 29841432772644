import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

const StyleNavLink = styled(Link)<any>`
  margin: 0;
  padding: 0 14px;
  text-decoration: none;
  text-transform: capitalize;
  font-weight: 400;
  font-family: ${props => props.theme.type.fontFamily};
  color: ${props => props.theme.colors.text};
  font-size: ${props => props.theme.type.nav.fontSize}px;
  line-height: ${props => props.theme.type.nav.lineHeight}px;

  transition: all 0.15s ease-in-out;

  &:hover {
    color: ${props => props.theme.colors.hover};
  }

  @media (max-width: 767px) {
    font-size: 64px;
    line-height: 75px;

    padding: 16px 0;

    &:last-of-type {
      padding-bottom: 32px;
    }
  }
  @media (max-width: 415px) {
    font-size: 24px;
    line-height: 28px;
  }
`;

const StyledAnchor = styled.a<any>`
  margin: 0;
  padding: 0 20px;
  text-decoration: none;
  text-transform: capitalize;
  font-weight: 400;
  font-family: ${props => props.theme.type.fontFamily};
  color: ${props => props.theme.colors.text};
  font-size: ${props => props.theme.type.nav.fontSize}px;
  line-height: ${props => props.theme.type.nav.lineHeight}px;

  transition: all 0.15s ease-in-out;

  &:hover {
    color: ${props => props.theme.colors.hover};
  }

  @media (max-width: 767px) {
    font-size: 64px;
    line-height: 75px;

    padding: 16px 0;

    &:last-of-type {
      padding-bottom: 32px;
    }
  }
  @media (max-width: 415px) {
    font-size: 24px;
    line-height: 28px;
  }
`;

interface IMenuLink {
  option: string;
  id: number | string;
  onClick?: () => {};
}

export const MenuLink = ({ option, id, onClick }: IMenuLink) =>
  option !== "store" ? (
    <StyleNavLink key={id} to={`/${option}`} onClick={onClick}>
      {option}
    </StyleNavLink>
  ) : (
    <StyledAnchor href="https://wearetyphoon.bandcamp.com" target="_blank">
      {option}
    </StyledAnchor>
  );
