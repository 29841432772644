
export const themeMode = {
  light: {
    colors: {
      text: "#000",
      background: "#fff",
      hover: "#BDBDBD",
      disabled: "#828282",
    },
  },
  dark: {
    colors: {
      text: "#fff",
      background: "#000",
      hover: "#BDBDBD",
      disabled: "#828282",
    },
  }
};

export const getMode = mode => ({
  ...themeMode[mode]
});
