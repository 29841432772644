import { useState, useEffect } from "react";
import { useDarkMode } from "./useDarkMode";
import { useViewport } from "../../utils/useViewport";
import { getTypography } from "../Theme/typography";
import { getMode } from "../Theme/darkLightMode";

const createCompositeTheme = (mode, currentBreakPoint) => ({
  ...getMode(mode),
  type: {
    ...getTypography(currentBreakPoint),
  },
  layout: {
    ...layout,
  },
});

export const useTheme = () => {
  const [mode, toggleMode] = useDarkMode();
  const { currentBreakPoint } = useViewport();
  const [theme, setTheme] = useState(
    createCompositeTheme(mode, currentBreakPoint)
  );

  useEffect(() => {
    setTheme(createCompositeTheme(mode, currentBreakPoint));
  }, [mode, currentBreakPoint]);

  return { theme, toggleMode, mode };
};

const layout = {
  baseLine: 8,
  gutter: 40,
};
