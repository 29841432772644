import React from "react"
import { Header } from "./Header";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import { Menu } from "./Header/Menu";
import { useMenu } from './Header/useMenu';
import { useTheme } from "../assets/Hooks/useTheme";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${props => props.theme.colors.background};
    transition: background-color .25s ease-in-out;
    margin: 0;
    padding: 0;
  }

`

export const Layout = ({ children }) => {
  const { theme, toggleMode, mode } = useTheme();
  const [menu, toggleMenu] = useMenu(false);
  const navigationButtonHandlers = {
    toggleMode, toggleMenu
  }

  return (
    <>
      <GlobalStyle theme={theme} />
      <ThemeProvider theme={theme}>
        <Header {...navigationButtonHandlers} mode={mode} />
        <Menu isOpen={menu} {...navigationButtonHandlers} theme={theme} />
        {children}
      </ThemeProvider>
    </>
  );
};
