import React from "react";
import { Link } from "gatsby";
import Styled from "styled-components";
import { DarkModeIcon } from "../../assets/Icons/DarkModeIcon";
import { IconButton } from "../IconButton";
import { useViewport } from "../../utils/useViewport";
import { MenuLink } from "./MenuLink";

const HeaderContainer = Styled.div`
  width: calc(100% - ${props => props.theme.layout.baseLine * 10}px); 
  height: 108px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: transparent;

  padding: 0 ${({ theme }) => theme.layout.baseLine * 5}px;

  transition: all .25s ease-in-out;
`;

const StyledMastLink = Styled(Link)<any>`
  margin: 0;
  text-decoration: none;
  font-weight: bold;
  font-family: ${props => props.theme.type.fontFamily};
  color: ${props => props.theme.colors.text};
  font-size: ${props => props.theme.type.nav.fontSize}px;
  line-height: ${props => props.theme.type.nav.lineHeight}px;
`;

const StyledNavLeftContainer = Styled.div`
  display: flex;
  justify-content: flex-start;
  width: 50%;

  // Have to do this from the parent container
  a:nth-of-type(2) {
    padding-left: 40px;
  }
`;

const StyledNavRightContainer = Styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
`;

export const StyledHeaderButton = Styled.button`
  position: relative;
  background-color: transparent;
  outline: none;
  border: none;
  padding: 0;
  height: 40px;

  font-family: ${props => props.theme.type.fontFamily};
  color: ${props => props.theme.colors.text};
  font-size: ${props => props.theme.type.nav.fontSize}px;
  line-height: ${props => props.theme.type.nav.lineHeight}px;

  padding: 0 20px;


  &:hover {
    cursor: pointer;
    color: ${props => props.theme.colors.hover};
  }
  &:last-of-type {
    padding-right: 0;
  }

  @media (max-width: 400px) {
    padding: 0 8px;
  }
`;

export const MENU_SITE_PAGES = [
  "news",
  "store",
  "tour",
  "discography",
  "contact",
];

export const Header = ({ toggleMode, toggleMenu, mode }) => {
  const { isMobile } = useViewport();
  return (
    <HeaderContainer>
      <StyledNavLeftContainer>
        <StyledMastLink to="/">Typhoon</StyledMastLink>
        {!isMobile &&
          MENU_SITE_PAGES.map((option, index) => (
            <MenuLink key={index} option={option} id={index} />
          ))}
      </StyledNavLeftContainer>

      <StyledNavRightContainer>
        {!isMobile && (
          <IconButton
            onClick={toggleMode}
            Icon={<DarkModeIcon mode={mode} />}
          />
        )}
        {isMobile && (
          <StyledHeaderButton onClick={toggleMenu}>Menu</StyledHeaderButton>
        )}
      </StyledNavRightContainer>
    </HeaderContainer>
  );
};
