import React from "react";

export const DarkModeIcon = ({ mode }): JSX.Element => {
  const halfMoon = mode === "dark" ? "white" : "black";
  const fullMoon = halfMoon === "black" ? "white" : "black";
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="16.6692"
        cy="16.6692"
        r="12"
        transform="rotate(34.186 16.6692 16.6692)"
        fill={halfMoon}
      />
      <path
        d="M9.79994 25.8924C4.88503 22.2375 3.68165 15.3229 7.15619 10.2076C10.6307 5.09225 17.5021 3.6623 22.7112 6.88408L9.79994 25.8924Z"
        fill={fullMoon}
        stroke={halfMoon}
      />
    </svg>
  );
};
