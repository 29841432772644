import { useState, useEffect } from "react";

export const useMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    window.localStorage.setItem("menu", !isMenuOpen ? "open" : "closed");
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const localStorageMenu = window.localStorage.getItem("menu");
    localStorageMenu && setIsMenuOpen(localStorageMenu === "open");
  }, []);

  return [isMenuOpen, toggleMenu];
};
