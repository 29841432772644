import { useState, useEffect, useCallback } from "react";

const calcIsMobile = width => width < 768;
const calcBreakPoint = width => {
  if (width > 1280) return "extraLarge";
  if (width > 1024) return "large";
  if (width > 768) return "medium";
  if (width > 320) return "small";
};
 
export const useViewport = () => {
  const isBrowser = typeof window !== "undefined";
  const INNER_WIDTH = isBrowser ? window.innerWidth : 1000;
  const [isMobile, setIsMobile] = useState(calcIsMobile(INNER_WIDTH));
  const [currentBreakPoint, setCurrentBreakPoint] = useState(
    calcBreakPoint(INNER_WIDTH)
  );

  const handleWindowResize = useCallback(() => {
    const innerWidth = window.innerWidth;
    const isMobileNextState = calcIsMobile(innerWidth);
    const nextBP = calcBreakPoint(innerWidth);

    if (nextBP !== currentBreakPoint) {
      setCurrentBreakPoint(nextBP);
    }

    if (isMobileNextState !== isMobile) {
      setIsMobile(isMobileNextState);
    }
  }, [currentBreakPoint, isMobile]);

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [handleWindowResize]);

  return { isMobile, currentBreakPoint };
};
