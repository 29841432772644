import React, { FC } from "react";
import Styled from "styled-components";

const StyledIconButton = Styled.button`
  background-color: transparent;
  outline: none;
  border: none;
  padding: 0 0;
  &:hover {
    cursor: pointer;
  }
`;

interface IconButton {
  onClick: () => {};
  Icon: JSX.Element;
  className?: string;
}

export const IconButton = ({ onClick, Icon, className }: IconButton) => (
  <StyledIconButton className={className} onClick={onClick}>
    {Icon}
  </StyledIconButton>
);
