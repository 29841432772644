import { useEffect, useState } from "react";

export const useDarkMode = () => {
  const defaultTheme = "light";
  const isBrowser = typeof window !== "undefined";
  const localTheme = isBrowser
    ? window.localStorage.getItem("mode")
    : defaultTheme;
  const [mode, setMode] = useState(localTheme ? localTheme : "light");

  const persistMode = mode => {
    window.localStorage.setItem("mode", mode);
    setMode(mode);
  };
  const toggleMode = () => {
    mode === "light" ? persistMode("dark") : persistMode("light");
  };

  useEffect(() => {
    const localTheme = window.localStorage.getItem("mode");
    localTheme && persistMode(localTheme);
  }, []);

  return [mode, toggleMode];
};
