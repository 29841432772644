import React from "react";
import styled from "styled-components";
import { IconButton } from "../IconButton";
import { MENU_SITE_PAGES, StyledHeaderButton } from ".";
import { DarkModeIcon } from "../../assets/Icons/DarkModeIcon";
import { useViewport } from "../../utils/useViewport";
import { MenuLink } from "./MenuLink";

const StyledMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: calc(100% - ${props => props.theme.layout.gutter * 2}px);
  width: calc(100% - ${props => props.theme.layout.gutter * 2}px);
  padding: ${props => props.theme.layout.gutter}px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${props => props.theme.colors.background};
  z-index: 99;
`;

// @ts-ignore
const StyledCloseButton = styled(StyledHeaderButton)`
  width: 100%;
  text-align: right;
  font-size: ${props => props.theme.type.nav.fontSize}px;
  line-height: ${props => props.theme.type.nav.lineHeight}px;
`;

const DarkModeButton = styled(IconButton)`
  svg {
    transition: all 0.25s ease-in-out;
    width: 64px;
    height: 64px;
    @media (max-width: 375px) {
      width: 40px;
      height: 40px;
    }
  }
  padding-left: 0;
`;

const StyledMenuLinksContainer = styled.div`
  height: 100%;
  margin-top: ${props => props.theme.layout.baseLine * 8}px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const Menu = ({ isOpen, toggleMenu, toggleMode, theme }) => {
  const { isMobile } = useViewport();
  return isOpen && isMobile ? (
    <StyledMenuContainer>
      <StyledCloseButton onClick={toggleMenu}>Close</StyledCloseButton>

      <StyledMenuLinksContainer>
        {MENU_SITE_PAGES.map((option, index) => (
          <MenuLink
            key={option}
            option={option}
            id={`${option}-${index}`}
            onClick={toggleMenu}
          />
        ))}
        {isMobile && (
          <DarkModeButton
            Icon={<DarkModeIcon mode={theme.mode} />}
            onClick={toggleMode}
          />
        )}
      </StyledMenuLinksContainer>
    </StyledMenuContainer>
  ) : null;
};
