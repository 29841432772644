// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-discography-index-tsx": () => import("./../../../src/pages/discography/index.tsx" /* webpackChunkName: "component---src-pages-discography-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-index-tsx": () => import("./../../../src/pages/news/index.tsx" /* webpackChunkName: "component---src-pages-news-index-tsx" */),
  "component---src-pages-store-index-tsx": () => import("./../../../src/pages/store/index.tsx" /* webpackChunkName: "component---src-pages-store-index-tsx" */),
  "component---src-pages-tour-index-tsx": () => import("./../../../src/pages/tour/index.tsx" /* webpackChunkName: "component---src-pages-tour-index-tsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-discography-detail-tsx": () => import("./../../../src/templates/DiscographyDetail.tsx" /* webpackChunkName: "component---src-templates-discography-detail-tsx" */),
  "component---src-templates-news-article-tsx": () => import("./../../../src/templates/NewsArticle.tsx" /* webpackChunkName: "component---src-templates-news-article-tsx" */)
}

