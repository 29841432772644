export const navType = {
  small: {
    fontSize: 24,
    lineHeight: 28,
  },
  medium: {
    fontSize: 24,
    lineHeight: 28,
  },
  large: {
    fontSize: 24,
    lineHeight: 28,
  },
  extraLarge: {
    fontSize: 24,
    lineHeight: 28,
  }
};

export const menuType = {
  small: {
    fontSize: 40,
    lineHeight: 77,
  },
  medium: {
    fontSize: 70,
    lineHeight: 87,
  },
  large: {
    fontSize: 100,
    lineHeight: 117,
  },
  extraLarge: {
    fontSize: 100,
    lineHeight: 117,
  },
};

export const fontFamily = {
  small: "IBM Plex Sans, sans-serif",
  medium: "IBM Plex Sans, sans-serif",
  large: "IBM Plex Sans, sans-serif",
  extraLarge: "IBM Plex Sans, sans-serif",
};

export const getTypography = currentBP => ({
  menu: {
    ...menuType[currentBP],
  },
  nav: {
    ...navType[currentBP],
  },
  fontFamily: fontFamily[currentBP],
});
